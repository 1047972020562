export default {
  'app.setting.pagestyle': 'Pengaturan style Halaman',
  'app.setting.pagestyle.dark': 'Style Gelap',
  'app.setting.pagestyle.light': 'Style Cerah',
  'app.setting.content-width': 'Lebar Konten',
  'app.setting.content-width.fixed': 'Tetap',
  'app.setting.content-width.fluid': 'Fluid',
  'app.setting.themecolor': 'Theme Color',
  'app.setting.themecolor.dust': 'Dust Red',
  'app.setting.themecolor.volcano': 'Volcano',
  'app.setting.themecolor.sunset': 'Sunset Orange',
  'app.setting.themecolor.cyan': 'Cyan',
  'app.setting.themecolor.green': 'Polar Green',
  'app.setting.themecolor.daybreak': 'Daybreak Blue (bawaan)',
  'app.setting.themecolor.geekblue': 'Geek Glue',
  'app.setting.themecolor.purple': 'Golden Purple',
  'app.setting.navigationmode': 'Mode Navigasi',
  'app.setting.sidemenu': 'Susunan Menu Samping',
  'app.setting.topmenu': 'Susunan Menu Atas',
  'app.setting.fixedheader': 'Header Tetap',
  'app.setting.fixedsidebar': 'Sidebar Tetap',
  'app.setting.fixedsidebar.hint': 'Berjalan pada Susunan Menu Samping',
  'app.setting.hideheader': 'Sembunyikan Header ketika gulir ke bawah',
  'app.setting.hideheader.hint': 'Bekerja ketika Header tersembunyi dimunculkan',
  'app.setting.othersettings': 'Pengaturan Lainnya',
  'app.setting.weakmode': 'Mode Lemah',
  'app.setting.copy': 'Salin Pengaturan',
  'app.setting.copyinfo':
    'Berhasil disalin，tolong ubah defaultSettings pada src/Model/setting.js',
  'app.setting.production.hint':
    'Panel pengaturan hanya muncul pada lingkungan pengembangan, silahkan modifikasi secara menual',
};
