export default {
  'pages.layouts.userLayout.title':
    'পিঁপড়া ডিজাইন হচ্ছে সিহু জেলার সবচেয়ে প্রভাবশালী ওয়েব ডিজাইনের স্পেসিফিকেশন',
  'pages.login.accountLogin.tab': 'অ্যাকাউন্টে লগইন',
  'pages.login.accountLogin.errorMessage': 'ভুল ব্যবহারকারীর নাম/পাসওয়ার্ড(admin/ant.design)',
  'pages.login.failure': 'লগইন ব্যর্থ হয়েছে। আবার চেষ্টা করুন!',
  'pages.login.success': 'সফল লগইন!',
  'pages.login.username.placeholder': 'ব্যবহারকারীর নাম: admin or user',
  'pages.login.username.required': 'আপনার ব্যবহারকারীর নাম ইনপুট করুন!',
  'pages.login.password.placeholder': 'পাসওয়ার্ড: ant.design',
  'pages.login.password.required': 'আপনার পাসওয়ার্ড ইনপুট করুন!',
  'pages.login.phoneLogin.tab': 'ফোন লগইন',
  'pages.login.phoneLogin.errorMessage': 'যাচাইকরণ কোড ত্রুটি',
  'pages.login.phoneNumber.placeholder': 'ফোন নম্বর',
  'pages.login.phoneNumber.required': 'আপনার ফোন নম্বর ইনপুট করুন!',
  'pages.login.phoneNumber.invalid': 'ফোন নম্বরটি সঠিক নয়!',
  'pages.login.captcha.placeholder': 'যাচাইকরণের কোড',
  'pages.login.captcha.required': 'দয়া করে ভেরিফিকেশন কোডটি ইনপুট করুন!',
  'pages.login.phoneLogin.getVerificationCode': 'কোড পান',
  'pages.getCaptchaSecondText': 'সেকেন্ড',
  'pages.login.rememberMe': 'আমাকে মনে রাখুন',
  'pages.login.forgotPassword': 'পাসওয়ার্ড ভুলে গেছেন?',
  'pages.login.submit': 'প্রবেশ করুন',
  'pages.login.loginWith': 'লগইন করতে পারেন:',
  'pages.login.registerAccount': 'অ্যাকাউন্ট নিবন্ধন করুন',
  'pages.welcome.link': 'স্বাগতম',
  'pages.welcome.alertMessage': 'দ্রুত এবং শক্তিশালী ভারী শুল্ক উপাদান প্রকাশ করা হয়েছে।',
  'pages.admin.subPage.title': 'এই পৃষ্ঠাটি কেবল অ্যাডমিন দ্বারা দেখা যাবে',
  'pages.admin.subPage.alertMessage':
    'UMI UI এখন প্রকাশিত হয়েছে, অভিজ্ঞতা শুরু করতে npm run ui ব্যবহার করতে স্বাগতম।',
  'pages.searchTable.createForm.newRule': 'নতুন বিধি',
  'pages.searchTable.updateForm.ruleConfig': 'বিধি কনফিগারেশন',
  'pages.searchTable.updateForm.basicConfig': 'মৌলিক তথ্য',
  'pages.searchTable.updateForm.ruleName.nameLabel': 'বিধি নাম',
  'pages.searchTable.updateForm.ruleName.nameRules': 'বিধির নাম লিখুন!',
  'pages.searchTable.updateForm.ruleDesc.descLabel': 'বিধির বিবরণ',
  'pages.searchTable.updateForm.ruleDesc.descPlaceholder': 'কমপক্ষে পাঁচটি অক্ষর লিখুন',
  'pages.searchTable.updateForm.ruleDesc.descRules':
    'কমপক্ষে পাঁচটি অক্ষরের একটি বিধান বিবরণ লিখুন!',
  'pages.searchTable.updateForm.ruleProps.title': 'বৈশিষ্ট্য কনফিগার করুন',
  'pages.searchTable.updateForm.object': 'নিরীক্ষণ অবজেক্ট',
  'pages.searchTable.updateForm.ruleProps.templateLabel': 'বিধি টেম্পলেট',
  'pages.searchTable.updateForm.ruleProps.typeLabel': 'বিধি প্রকার',
  'pages.searchTable.updateForm.schedulingPeriod.title': 'সময়সূচী নির্ধারণ করুন',
  'pages.searchTable.updateForm.schedulingPeriod.timeLabel': 'শুরুর সময়',
  'pages.searchTable.updateForm.schedulingPeriod.timeRules': 'একটি শুরুর সময় চয়ন করুন!',
  'pages.searchTable.titleDesc': 'বর্ণনা',
  'pages.searchTable.ruleName': 'বিধি নাম প্রয়োজন',
  'pages.searchTable.titleCallNo': 'পরিষেবা কল সংখ্যা',
  'pages.searchTable.titleStatus': 'অবস্থা',
  'pages.searchTable.nameStatus.default': 'ডিফল্ট',
  'pages.searchTable.nameStatus.running': 'চলমান',
  'pages.searchTable.nameStatus.online': 'অনলাইন',
  'pages.searchTable.nameStatus.abnormal': 'অস্বাভাবিক',
  'pages.searchTable.titleUpdatedAt': 'সর্বশেষ নির্ধারিত',
  'pages.searchTable.exception': 'ব্যতিক্রম জন্য কারণ লিখুন!',
  'pages.searchTable.titleOption': 'অপশন',
  'pages.searchTable.config': 'কনফিগারেশন',
  'pages.searchTable.subscribeAlert': 'সতর্কতা সাবস্ক্রাইব করুন',
  'pages.searchTable.title': 'ইনকয়েরি ফরম',
  'pages.searchTable.new': 'নতুন',
  'pages.searchTable.chosen': 'নির্বাচিত',
  'pages.searchTable.item': 'আইটেম',
  'pages.searchTable.totalServiceCalls': 'পরিষেবা কলগুলির মোট সংখ্যা',
  'pages.searchTable.tenThousand': '000',
  'pages.searchTable.batchDeletion': 'একসাখে ডিলিট',
  'pages.searchTable.batchApproval': 'একসাখে অনুমোদন',
};
