import React from 'react'
import { Typography } from 'antd'

const defaultOptions: Intl.DateTimeFormatOptions = {
  timeZone: 'UTC',
  hour12: false,
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric'
}

type IComponentProps = {
  dateTime: string|null;
  locale?: string;
  options?: Intl.DateTimeFormatOptions
  [key: string]: any
}

const { Text } = Typography;

const FormatDateTime: React.FC<IComponentProps> = (props): React.ReactElement => {
  let locale = props?.locale || 'en-US'

  const formatValue = (v: string) => {
    const opt = props?.options === null ? defaultOptions : { ...defaultOptions, ...props.options }

    return Intl.DateTimeFormat(locale, opt).format(new Date(v))
  }

  return (
    props?.dateTime === null ?
    <Text {...props}>-</Text> :
    <Text {...props}>{ formatValue(props.dateTime) }</Text>
  )
}

export default FormatDateTime

export { FormatDateTime }
