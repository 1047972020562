import React from 'react'
import { Tag, Typography } from 'antd'

interface TagListItem {
  id: number,
  name: string
}

type IComponentProps = {
  tags: TagListItem[]
  [key: string]: any
}

const { Text } = Typography;

const Tags: React.FC<IComponentProps> = (props): React.ReactElement => {
  return (<>
    { props.tags.length
      ? props.tags.map(tag => (
        <Tag color="purple" key={tag.name} {...props}>{ tag.name }</Tag>
      ))
      : <Text type="danger" {...props}>empty</Text>
    }
  </>)
}

export default Tags

export { Tags }
