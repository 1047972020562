import React from 'react'
import { Tag, Typography } from 'antd'

interface CategoryListItem {
  id: number,
  name: string
}

type IComponentProps = {
  categories: CategoryListItem[]
  [key: string]: any
}

const { Text } = Typography;

const Categories: React.FC<IComponentProps> = (props): React.ReactElement => {
  return (<>
    { props.categories.length
      ? props.categories.map(category => (
        <Tag color="geekblue" key={category.name} {...props}>{ category.name }</Tag>
      ))
      : <Text type="danger" {...props}>empty</Text>
    }
  </>)
}

export default Categories

export { Categories }
