import { createEffect, createEvent, createStore, sample } from 'effector'
import { request } from '@umijs/max';
import { getToken, logout } from '@/pages/Auth/Login/Model/Authentication'

const reset = createEvent()

const $currentUser = createStore<CurrentUser|null>(null)

const getCurrentUserFx = createEffect(async (options?: { [key: string]: any }) => {
  const token = getToken()

  return await request<CurrentUserApiResponse>('/api/v1/account/current-user/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    ...(options || {}),
  })
})

$currentUser
  .reset([reset])

sample({
  clock: getCurrentUserFx.doneData,
  source: $currentUser,
  fn: (src: CurrentUser|null, clk: CurrentUserApiResponse) => {
    if (clk.user) {
      return { ...clk.user, avatar: '' }
    }

    return src
  },
  target: $currentUser
})

logout.watch(() => { reset() })

export { $currentUser, getCurrentUserFx }
