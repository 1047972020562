import React from 'react'
import styles from './TableShowsFromTo.less'

type IComponentProps = {
  page?: number;
  pageSize?: number;
  total?: number;
}

const TableShowsFromTo: React.FC<IComponentProps> = ({ page = 0, pageSize = 50, total = 0 }): React.ReactElement => {
  let perPage = pageSize

  if (perPage === 0) {
    perPage = 1
  }

  const offset = (perPage * page) - perPage + 1
  const maxPages = Math.ceil(total / perPage)
  const to = maxPages === page ? total : offset + perPage - 1

  return (
    <div className={styles.tableShowsInfo__Container}>
      <div className={styles.tableShowsInfo}>Shows <b>{offset}</b>-<b>{to}</b> of <b>{total}</b> records</div>
    </div>
  )
}

export default TableShowsFromTo

export { TableShowsFromTo }
