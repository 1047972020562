const isDev = process.env.NODE_ENV === 'development';

export default {
  baseApiUrl: isDev ? 'http://localhost:8000' : ''
}

/** Список опций для отклонения галлерей при аппруве */
export let galleryDeclineReasons = [
  'Битый контент, пустые фото',
  'Запрещенный контент',
  'ЛГБТ',
  'Менее 3 фото',
  'Фото очень низкого качества',
]

export const videoDeleteReasons = [
  'ДП, Малолетки',
  'Насилие, принуждение, съемка скрытой камерой (не постановочное)',
  'Грязь (Писсинг, золотой дождь, копро)',
  'ЛГБТ',
  'Битое видео (проблемы с проигрыванием)',
  'Очень низкое качество видео',
  'Дубликат',
  'Навязчивая или много рекламы'
]
