// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import SmileOutlined from '/home/asat/Work/PhpProjects/packo-lc/front/node_modules/@ant-design/icons/es/icons/SmileOutlined';
import PlayCircleOutlined from '/home/asat/Work/PhpProjects/packo-lc/front/node_modules/@ant-design/icons/es/icons/PlayCircleOutlined';
import DoubleRightOutlined from '/home/asat/Work/PhpProjects/packo-lc/front/node_modules/@ant-design/icons/es/icons/DoubleRightOutlined';
import CrownOutlined from '/home/asat/Work/PhpProjects/packo-lc/front/node_modules/@ant-design/icons/es/icons/CrownOutlined';
import PictureOutlined from '/home/asat/Work/PhpProjects/packo-lc/front/node_modules/@ant-design/icons/es/icons/PictureOutlined';
import LaptopOutlined from '/home/asat/Work/PhpProjects/packo-lc/front/node_modules/@ant-design/icons/es/icons/LaptopOutlined';
import WarningOutlined from '/home/asat/Work/PhpProjects/packo-lc/front/node_modules/@ant-design/icons/es/icons/WarningOutlined';
import TableOutlined from '/home/asat/Work/PhpProjects/packo-lc/front/node_modules/@ant-design/icons/es/icons/TableOutlined';
export default { SmileOutlined, PlayCircleOutlined, DoubleRightOutlined, CrownOutlined, PictureOutlined, LaptopOutlined, WarningOutlined, TableOutlined };
