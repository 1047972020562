import { createEffect, createEvent, createStore, sample } from 'effector'

import { request } from '@umijs/max'

const localStorageKey = 'authState'

const authenticated = createEvent()
const logout = createEvent()
const failedAuth = createEvent<Error>()
const updateStore = createEvent<AuthState>()
const reset = createEvent()

const $authState = createStore<AuthState>({
  token: '',
  refreshToken: ''
})

$authState
  .on(updateStore, (state, result) => ({ ...result }))
  .reset([reset])

// загрузка токена в сторадж при первой загрузке аппликухи, если есть.
const data = localStorage.getItem(localStorageKey)

if (null !== data) {
  const storedState: AuthState = JSON.parse(data);
  updateStore({ ...$authState.getState(), token: storedState?.token || '', refreshToken: storedState?.refreshToken || '' });
}

const signInFx = createEffect(async (body: LoginParams, options?: { [key: string]: any }) => {
  const result = await request<LoginResult>('/api/v1/sign-in/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  })

  if (result.error !== '') {
    throw new Error(result.error)
  }

  return result
})

const signOutFx = createEffect(async (options?: { [key: string]: any }) => {
  return await request<Record<string, any>>('/api/v1/sign-out/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${$authState.getState().token}`,
    },
    ...(options || {}),
  })
})

const saveToLocalStorageFx = createEffect(async (state: AuthState) => {
  return localStorage.setItem(localStorageKey, JSON.stringify(state))
})

const removeFromLocalStorageFx = createEffect(async () => {
  return localStorage.removeItem(localStorageKey)
})

sample({
  clock: signInFx.doneData,
  source: $authState,
  fn: (src: AuthState, clk: LoginResult) => ({ ...src, token: clk.token, refreshToken: clk.refreshToken }),
  target: [$authState, authenticated, saveToLocalStorageFx],
})

sample({
  clock: signInFx.failData,
  target: failedAuth,
})

sample({
  clock: signOutFx.doneData,
  source: $authState,
  target: [reset, logout, removeFromLocalStorageFx],
})

const isAuthenticated = () => $authState.getState().token !== ''

const getToken = () => $authState.getState().token

export { signInFx, signOutFx, authenticated, logout, failedAuth, isAuthenticated, getToken }
