/**
 * 这个文件作为组件的目录
 * 目的是统一管理对外输出的组件，方便分类
 */
/**
 * 布局组件
 */
import Footer from './Footer'
import { Question, SelectLang } from './RightContent'
import { AvatarDropdown, AvatarName } from './RightContent/AvatarDropdown'
import { FormatDateTime } from './Shared/FormatDateTime'
import { Categories } from './Shared/Categories'
import { Tags } from './Shared/Tags'
import { TableShowsFromTo } from './Shared/TableShowsFromTo'

export { Footer, Question, SelectLang, AvatarDropdown, AvatarName, FormatDateTime, Categories, Tags, TableShowsFromTo };
