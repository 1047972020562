export default {
  'app.setting.pagestyle': 'ページスタイル設定',
  'app.setting.pagestyle.dark': 'ダークスタイル',
  'app.setting.pagestyle.light': 'ライトスタイル',
  'app.setting.content-width': 'コンテンツの幅',
  'app.setting.content-width.fixed': '固定',
  'app.setting.content-width.fluid': '流体',
  'app.setting.themecolor': 'テーマカラー',
  'app.setting.themecolor.dust': 'ダストレッド',
  'app.setting.themecolor.volcano': 'ボルケ-ノ',
  'app.setting.themecolor.sunset': 'サンセットオレンジ',
  'app.setting.themecolor.cyan': 'シアン',
  'app.setting.themecolor.green': 'ポーラーグリーン',
  'app.setting.themecolor.daybreak': '夜明けの青（デフォルト）',
  'app.setting.themecolor.geekblue': 'ギーク ブルー',
  'app.setting.themecolor.purple': 'ゴールデンパープル',
  'app.setting.navigationmode': 'ナビゲーションモード',
  'app.setting.sidemenu': 'サイドメニューのレイアウト',
  'app.setting.topmenu': 'トップメニューのレイアウト',
  'app.setting.fixedheader': '固定ヘッダー',
  'app.setting.fixedsidebar': '固定サイドバー',
  'app.setting.fixedsidebar.hint': 'サイドメニューのレイアウトで動作します',
  'app.setting.hideheader': 'スクロール時の非表示ヘッダー',
  'app.setting.hideheader.hint': '非表示ヘッダーが有効になっている場合に機能します',
  'app.setting.othersettings': 'その他の設定',
  'app.setting.weakmode': 'ウィークモード',
  'app.setting.copy': 'コピー設定',
  'app.setting.copyinfo':
    'コピーが成功しました。src/Model/setting.jsのdefaultSettingsを置き換えてください',
  'app.setting.production.hint': '設定パネルは開発環境でのみ表示されます。手動で変更してください',
};
